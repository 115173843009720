<template>
	<div class="modal-wrapper modal-wrapper--chat">

		<div class="modal-overlay"></div>

		<form class="offscreen"
					id="formExpertsChat"
					@submit.prevent="sendMessage"></form>

		<section class="experts-chat-modal modal card shadow rythm-v"
						 :class="isMinimized ? 'is-minimized' : ''"
						 role="dialog">

			<div class="modal-content">
				<header>
					<div class="flex-row flex-center-v flex-align-spaced flex-nowrap">

						<h2 class="flex-row flex-center-v flex-gap-s flex-nowrap"
								@click.prevent="isMinimized && minimizeToggle($event, false)">
							<svg class="icon"
									 role="img"
									 viewBox="0 0 15 15"
									 fill="none"
									 xmlns="http://www.w3.org/2000/svg"
									 width="15"
									 height="15"><path d="M3.5 11.493H4v-.5h-.5v.5zm0 2.998H3a.5.5 0 00.8.4l-.3-.4zm4-2.998v-.5h-.167l-.133.1.3.4zm-3-7.496H4v1h.5v-1zm6 1h.5v-1h-.5v1zm-6 1.998H4v1h.5v-1zm4 1H9v-1h-.5v1zM3 11.493v2.998h1v-2.998H3zm.8 3.398l4-2.998-.6-.8-4 2.998.6.8zm3.7-2.898h6v-1h-6v1zm6 0c.829 0 1.5-.67 1.5-1.5h-1c0 .277-.223.5-.5.5v1zm1.5-1.5V1.5h-1v8.994h1zM15 1.5c0-.83-.671-1.5-1.5-1.5v1c.277 0 .5.223.5.5h1zM13.5 0h-12v1h12V0zm-12 0C.671 0 0 .67 0 1.5h1c0-.277.223-.5.5-.5V0zM0 1.5v8.993h1V1.5H0zm0 8.993c0 .83.671 1.5 1.5 1.5v-1a.499.499 0 01-.5-.5H0zm1.5 1.5h2v-1h-2v1zm3-6.996h6v-1h-6v1zm0 2.998h4v-1h-4v1z" fill="currentColor"></path></svg>
							<span class="caption">Échange entre experts</span>
						</h2>

						<button type="button"
										class="button--icon button-minimize-toggle"
										@click="minimizeToggle($event)">
							<svg class="icon"
									 viewBox="0 0 15 15"
									 fill="none"
									 xmlns="http://www.w3.org/2000/svg"
									 width="15"
									 height="15"><path d="M14 5l-6.5 7L1 5" fill="none" stroke="currentColor" stroke-linecap="square"></path></svg>
						</button>
					</div>

					<div class="text-ellipsis"><em>{{ question.title }}</em></div>
				</header>

				<div class="content flex-col flex-gap">
					<div class="chat-messages flex-item--100"
							 ref="chatMessages">

						<ul class="messages-list">
							<li class="message"
									v-if="!messages.length"><em>Aucun message pour l’instant.</em></li>

							<li class="message"
									:class="isMyMessage(message.user_id) ? 'is-mine' : ''"
									v-for="message in messages">
								<p v-html="message.message"></p>

								<a v-if="message.document_hash"
									 class="button--link flex-row flex-center-v flex-gap-s flex-nowrap"
									 :href="`${API_URL}/file/${message.document_hash}?jwt=${userToken}`"
									 target="_blank"
									 rel="noreferrer">
									<svg role="img"
											 class="icon icon-file"
											 xmlns="http://www.w3.org/2000/svg"
											 width="18"
											 height="22"
											 fill="none"
											 viewBox="0 0 18 22"><path fill="currentColor" fill-rule="evenodd" d="M0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H11C11.2652 0 11.5196 0.105357 11.7071 0.292893L17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7V19C18 19.7957 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7957 22 15 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.31607 20.5587 0 19.7957 0 19V3C0 2.20435 0.31607 1.44129 0.87868 0.87868ZM3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20H15C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19V7.41421L10.5858 2H3Z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M11 0C11.5523 0 12 .447715 12 1V6H17C17.5523 6 18 6.44772 18 7 18 7.55228 17.5523 8 17 8H11C10.4477 8 10 7.55228 10 7V1C10 .447715 10.4477 0 11 0zM4 12C4 11.4477 4.44772 11 5 11H13C13.5523 11 14 11.4477 14 12 14 12.5523 13.5523 13 13 13H5C4.44772 13 4 12.5523 4 12zM4 16C4 15.4477 4.44772 15 5 15H13C13.5523 15 14 15.4477 14 16 14 16.5523 13.5523 17 13 17H5C4.44772 17 4 16.5523 4 16zM4 8C4 7.44772 4.44772 7 5 7H7C7.55228 7 8 7.44772 8 8 8 8.55228 7.55228 9 7 9H5C4.44772 9 4 8.55228 4 8z" clip-rule="evenodd"/></svg>
									{{ message.document_name }}
								</a>

								<div class="metas">
									<span class="expert-name">{{ `${message.firstname} ${message.lastname}` }}</span>
									<time :datetime="message.date">{{ formatDate(message.date) }}</time>
								</div>
							</li>
						</ul>

					</div>

					<div class="chat-editor fill-width">

						<div class="input-group">
							<label hidden
										 for="message">Votre message</label>
							<textarea name="message"
												id="message"
												form="formExpertsChat"
												cols="30"
												rows="4"
												placeholder="Votre réponse…"
												v-model="chatMessage"></textarea>
						</div>

						<div class="flex-col">
							<div class="current-file flex-row flex-center-v flex-gap-s flex-nowrap"
									 v-if="currentFile">
								<svg role="img"
										 class="icon icon-file"
										 xmlns="http://www.w3.org/2000/svg"
										 width="18"
										 height="22"
										 fill="none"
										 viewBox="0 0 18 22"><path fill="currentColor" fill-rule="evenodd" d="M0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H11C11.2652 0 11.5196 0.105357 11.7071 0.292893L17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7V19C18 19.7957 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7957 22 15 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.31607 20.5587 0 19.7957 0 19V3C0 2.20435 0.31607 1.44129 0.87868 0.87868ZM3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20H15C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19V7.41421L10.5858 2H3Z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M11 0C11.5523 0 12 .447715 12 1V6H17C17.5523 6 18 6.44772 18 7 18 7.55228 17.5523 8 17 8H11C10.4477 8 10 7.55228 10 7V1C10 .447715 10.4477 0 11 0zM4 12C4 11.4477 4.44772 11 5 11H13C13.5523 11 14 11.4477 14 12 14 12.5523 13.5523 13 13 13H5C4.44772 13 4 12.5523 4 12zM4 16C4 15.4477 4.44772 15 5 15H13C13.5523 15 14 15.4477 14 16 14 16.5523 13.5523 17 13 17H5C4.44772 17 4 16.5523 4 16zM4 8C4 7.44772 4.44772 7 5 7H7C7.55228 7 8 7.44772 8 8 8 8.55228 7.55228 9 7 9H5C4.44772 9 4 8.55228 4 8z" clip-rule="evenodd"/></svg>
								<span class="caption">{{ currentFile.name }}</span>
							</div>

							<div class="upload-validations small"
									 v-if="validations.file.error">{{ validations.file.error }}</div>
						</div>


						<div class="input-group fill-width flex-row flex-align-spaced flex-gap">
							<div class="flex-row flex-align-right flex-gap">
								<button class="button--outline"
												type="button"
												@click="minimizeToggle($event)">Réduire</button>

								<button class="button--outline"
												type="button"
												@click="closeModal">Fermer</button>
							</div>

							<div class="upload-controls flex-row flex-align-right flex-gap">

								<label v-show="!this.currentFile"
											 class="button-file button--outline flex-basis-auto"
											 :class="validations.file.invalid ? 'invalid' : ''"
											 :disabled="isLoading"
											 for="fileUpload"
											 title="Joindre un document">

									<svg role="img"
											 class="icon icon-file-plus"
											 xmlns="http://www.w3.org/2000/svg"
											 width="24"
											 height="24"
											 viewBox="0 0 24 24"
											 fill="none"
											 stroke="currentColor"
											 stroke-width="2"
											 stroke-linecap="round"
											 stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="12" y1="18" x2="12" y2="12"></line><line x1="9" y1="15" x2="15" y2="15"></line></svg>

									<input class="offscreen "
												 :disabled="isLoading"
												 type="file"
												 name="fileUpload"
												 id="fileUpload"
												 ref="fileUpload"
												 :accept="acceptedFiles"
												 @change="handleFileSelect" />
								</label>

								<button v-show="this.currentFile"
												type="button"
												class="button-file button--outline flex-basis-auto"
												title="Retirer le document"
												@click="resetFileUpload">
									<svg role="img"
											 class="icon icon-file-minus"
											 xmlns="http://www.w3.org/2000/svg"
											 width="24"
											 height="24"
											 viewBox="0 0 24 24"
											 fill="none"
											 stroke="currentColor"
											 stroke-width="2"
											 stroke-linecap="round"
											 stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="9" y1="15" x2="15" y2="15"></line></svg>
								</button>

								<button class="button--primary"
												type="submit"
												form="formExpertsChat"
												:disabled="!readyToSend || isLoading">
									<span class="caption">Envoyer</span>
									<svg class="icon icon-send"
											 role="img"
											 aria-hidden="true"
											 fill="none"
											 height="16"
											 viewBox="0 0 14 16"
											 width="14"
											 xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m13.345 1.64566c.0147-.24055-.1104-.46862-.3232-.58911s-.4768-.112728-.6818.02005l-11.702467 7.57733c-.23165.14999-.340325.4279-.270344.69134.069982.26343.303111.45401.579942.47409l6.632549.48114 3.74122 5.3924c.1561.2251.441.3278.7086.2555.2676-.0722.4587-.3035.4753-.5752zm-5.76338 7.37907-4.64181-.33674 9.03229-5.84841-.6485 10.59532-2.61829-3.77396 1.09391-1.8581c.17915-.3043.07283-.69341-.23746-.8691-.3103-.17569-.70707-.07143-.88622.23287z" fill="currentColor" fill-rule="evenodd" /></svg>
								</button>
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>
	</div>
</template>


<script>
import { HTTP } from "../http-common.js";
import isMobile from "ismobilejs";

export default {
	name: "ExpertsChat",
	props: ["question", "messages", "status", "minimized"],

	data: function () {
		return {
			API_URL: HTTP.defaults.baseURL,
			userToken: this.$store.state.userToken,
			chatMessage: '',
			isLoading: false,
			isMinimized: false,
			currentFile: null,

			validations: {
				file: {
					invalid: false,
					error: "",
				},
			},

			allowedMimes: [
				// text processing documents
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'application/vnd.oasis.opendocument.text',
				'application/pdf',
				'text/csv',
				'text/plain',
				// presentations
				'application/vnd.ms-powerpoint',
				'application/vnd.openxmlformats-officedocument.presentationml.presentation',
				'application/vnd.oasis.opendocument.presentation',
				// sheets
				'application/vnd.ms-excel',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/vnd.oasis.opendocument.spreadsheet',
				// images
				'image/jpg',
				'image/jpeg',
				'image/png',
				'image/gif',
				'image/webp',
				// videos
				'video/mp4',
				'video/x-msvideo',
				'video/quicktime',
				'video/mpeg',
				// audio
				'audio/x-wav',
				'audio/webm',
				'audio/aac',
				'audio/ogg',
				'audio/mp3',
				'audio/mpeg',
				// Archives
				'application/zip'
			]
		};
	},

	computed: {

		acceptedFiles() {
			return this.allowedMimes.join(',');
		},

		onMobile() {
			return isMobile(window.navigator).any;
		},

		// Current user data
		user: function () {
			return this.$store.state.userData;
		},

		readyToSend() {
			const hasMessage = this.chatMessage.length > 0;
			const hasUpload = this.currentFile;
			const uploadOk = (hasUpload && !this?.validations?.file?.error);
			return (!hasMessage && uploadOk) || (hasMessage && !hasUpload) || (hasMessage && uploadOk);
		},

	},

	methods: {

		resetFileUpload(e) {
			this.$refs.fileUpload.value = '';
			this.currentFile = null;
			this.validations.file.invalid = false;
			this.validations.file.error = "";
		},

		handleFileSelect(e) {
			// let fileInput = e.target;
			const fileInput = this.$refs.fileUpload;
			const file = fileInput?.files[0] || null;

			if (file) {
				this.currentFile = file;
				this.validations.file.invalid = false;
				this.validations.file.error = "";

				// file weight
				if (typeof FileReader !== "undefined") {
					let size = file.size;
					if (size > 20485760) {
						this.validations.file.invalid = true;
						this.validations.file.error = "Fichier trop lourd (> 20 Mo)."
					}
				}

				// file type
				if (!this.allowedMimes.includes(file.type)) {
					this.validations.file.invalid = true;
					this.validations.file.error = "Type de fichier non autorisé.";
				}

			} else {
				this.currentFile = null;
			}

		},

		isMyMessage(userID) {
			return parseInt(userID) === parseInt(this.user.id)
		},

		formatDate(str) {
			// dd/MM/yyyy HH:mm
			var formattedDate = new Date(str).toLocaleDateString('fr-FR', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit'
			});
			return formattedDate;
		},

		closeModal() {
			this.$emit("modalClose");
		},

		scrollToLastMessage() {
			const $chatMessages = this.$refs.chatMessages;
			$chatMessages.scrollTo({
				top: $chatMessages.scrollHeight,
				left: 0,
				behavior: "smooth",
			});
		},

		minimizeToggle(e, status = null) {
			const _status = status === null ? !this.isMinimized : status;
			this.isMinimized = _status;
			if (this.onMobile) this.toggleScrollLock(!_status);
			this.$emit('minimize', _status);
		},

		toggleScrollLock(status = true) {
			const root = document.getElementsByTagName('html')[0];
			if (status) root.classList.add('scroll-locked');
			else root.classList.remove('scroll-locked');
		},

		async sendMessage() {
			if (!this.question || !this.question.id) return false;
			this.isLoading = true;

			const file = this.$refs.fileUpload.files[0];
			let formData = new FormData();
			formData.append("message", this.chatMessage);
			formData.append("file", file);

			try {
				// send message
				await HTTP.post(`/chat/${this.question.id}`, formData);

				// reset form
				this.chatMessage = "";
				this.$refs.fileUpload.value = "";

				// reload messages
				await this.$store.dispatch("GET_EXPERTS_CHAT_MESSAGES", this.question.id);
				this.scrollToLastMessage();

			} catch (e) {
				let errorMsg = "Erreur lors de l’envoi !";

				if (e.status === 422) {
					errorMsg = "Type de fichier non autorisé.";
				}

				this.$toasted.global.appError({ message: errorMsg });
			}
			finally {
				this.isLoading = false;
				this.resetFileUpload();
			}
		},
	},

	watch: {
		status(isOpen) {
			if (isOpen) {
				this.minimizeToggle(null, false);
				this.scrollToLastMessage();
			}
		},
		minimized: {
			immediate: true,
			handler: function (after, before) {
				this.isMinimized = after;
			},
		},
		messages() {
			setTimeout(() => this.scrollToLastMessage(), 300);
		}
	}


};
</script>

<style lang="scss" scoped>
@import "~@/scss/01-settings/settings.colors";
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";
@import "~@/scss/06-components/vue-multiselect";
@import "~@/scss/99-utilities/_utilities.text";
@import "~@/scss/99-utilities/_utilities.align";
@import "~@/scss/99-utilities/_utilities.align";

.current-file {
	font-family: var(--font-monospace);
	font-size: .875rem;
	word-break: break-all;

	.icon {
		flex-shrink: 0;
		height: auto;
		width: 1em;
	}
}

.button-file {
	padding: .35em;

	.icon.icon-file-plus,
	.icon.icon-file-minus {
		flex-shrink: 0;
		height: auto;
		width: 1.25em;
	}
}

.icon-file-minus {
	color: $color-danger;
}


.chat-editor {
	display: flex;
	flex-flow: column nowrap;
	gap: .5rem
}

.upload-validations {
	color: #{$color-danger};
}

.flex-basis-auto {
	flex-basis: auto;
}

.modal-wrapper[open].modal-wrapper--chat {
	width: 0;
	height: 0;

	.modal-overlay {
		width: 0;
		height: 0;
		visibility: hidden;
	}

	header {
		cursor: default;
		padding: 1rem;

		h2 {
			color: $color-cta;

			.icon {
				width: 1em;
				height: auto;
			}
		}

		&>.icon {
			width: 22px;
			height: auto;
			color: $color-cta;
			transform: rotate(180deg);
		}
	}

	.modal-content .content {
		flex-grow: 1;
		padding: 0 1rem 1rem 1rem;
	}

	.chat-messages {
		flex-basis: 40dvh;
		flex-grow: 1;
		flex-shrink: 0;
		overflow-y: auto;
		padding: 1px; // li outlines
		width: 100%;

		.messages-list {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-end;
			margin-top: auto;

			gap: .8rem;
			list-style: none;
			margin: 0;
			padding: .5rem;
			padding: 0;
		}

		.message {
			display: flex;
			flex-flow: row wrap;
			font-size: .875rem;
			border-radius: 3px;
			background: hsl(0, 0%, 96%);
			padding: .5rem;
			width: 100%;
			gap: .5em;

			.metas {
				display: flex;
				justify-content: flex-end;
				flex-basis: 100%;
				flex-flow: row wrap;
				font-size: .875em;
				font-style: italic;

				gap: 1em;

				.expert-name {
					// flex-grow: 1;
					// color: $color-black;
				}

				time {
					color: #{$color-gray};

					text-align: right;
				}
			}

			p {
				margin-top: 0;
				flex-basis: 100%;
			}

			// Current user messages
			&.is-mine {
				// background-color: hsl(210, 40%, 95%);
				background-color: hsl(217, 90%, 96%);

				.metas {
					.expert-name {
						// font-weight: 600;
					}
				}
			}
		}
	}

	textarea {
		height: 10em;

		&::placeholder {
			font-style: italic;
		}

		&:focus {
			outline: 1px solid #188CC8;
		}
	}

	.button-minimize-toggle {
		svg {
			transition: transform ease-out .3s;
		}
	}

	.experts-chat-modal {
		right: 1rem;
		bottom: 1rem;
		top: 1rem;
		left: initial;
		transform: none;
		width: 30rem;
		max-width: 100%;
		padding: 0;
		border-color: $color-cta;
		box-shadow: 0 -8px 12px -5px #0000000f,
			0 0 40px #0000001c;

		.modal-content {
			height: 100%;
			display: flex;
			flex-flow: column nowrap;
			padding: 0;
			gap: 1rem;

			.flex-grower {
				flex-grow: 1 !important;
			}

			header {

				&>.icon {
					transform: rotate(0);
				}
			}
		}

		&.is-minimized {

			top: initial !important;

			.modal-content .content {
				display: none;
			}

			.button-minimize-toggle svg {
				transform: rotate(-180deg);
			}
		}
	}


}


@media (max-height:720px) {

	.modal-wrapper[open].modal-wrapper--chat {

		header {
			position: sticky;
			top: 0;
			background: #fafafa;
		}

		.chat-messages {
			overflow-y: initial;
		}

		.experts-chat-modal .modal-content {
			overflow-y: auto;
		}
	}
}



@media (max-width: 768px) {
	.modal-wrapper[open].modal-wrapper--chat .experts-chat-modal {
		bottom: 0;
		height: 100vh;
		height: 100dvh;
		left: 0;
		right: 0;
		top: 0;
		width: 100vw;
		width: 100dvw;
		border: none;

		&.is-minimized {
			height: auto;
		}
	}

	.modal-wrapper[open].modal-wrapper--chat .user-details-modal {
		height: auto;
	}

}

.input-group--controls {
	justify-content: center;

	button {
		margin: 0.5rem 0.5rem 0;
	}
}
</style>